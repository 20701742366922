import RightArrowFilled from '@/src/icons/RightArrowFilled';
import { LinkTouch } from '@/src/modules/ui/components/Link/LinkTouch';
import Section from '@/src/modules/ui/components/Section';
import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import styles from './MigrationBanner.module.scss';
import { Button } from '../../ui/components/Button';
import { useMutationUserPreferencesUpdate } from '../../user/mutations/useMutationUserPreferencesUpdate';
import { useQueryUserPreferences } from '../../user/queries/useQueryUserPreferences';
import { AnimatePresence, motion, useWillChange } from 'framer-motion';

const MotionSection = motion(Section);

interface MigrationBannerProps {
  className?: string;
}

const MigrationBanner: React.FC<MigrationBannerProps> = ({ className }) => {
  const willChange = useWillChange();
  const { data: preferences, isLoading } = useQueryUserPreferences();
  const mutateUserPreferences = useMutationUserPreferencesUpdate();
  const onDismiss = () => {
    if (!preferences) return;

    mutateUserPreferences.mutate({
      data: {
        frontend: {
          ...preferences?.frontend,
          migrateBannerHidden: true,
        },
      },
      showToast: false,
    });
  };

  return (
    <AnimatePresence>
      {!preferences?.frontend.migrateBannerHidden && !isLoading && (
        <MotionSection
          key="migration-banner"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, maxHeight: '100%' }}
          exit={{ opacity: 0, maxHeight: 0 }}
          transition={{ duration: 0.3 }}
          style={{ willChange: willChange } as unknown as CSSProperties}
          className={clsx(className)}
        >
          <div className={styles.main_container}>
            <button className={styles.arrow_button}>
              <RightArrowFilled color="blue" />
            </button>

            <div className={styles.text}>
              <p>Migrate from another app</p>
              <p>Import data from another service to switch to Fabric and get started quickly</p>
            </div>

            <Button onClick={onDismiss} variant="bg-quarternary">
              Dismiss
            </Button>
            <Button as={LinkTouch} href="/import" shallow variant="primary-inverse">
              Import
            </Button>
          </div>
        </MotionSection>
      )}
    </AnimatePresence>
  );
};

export default MigrationBanner;
